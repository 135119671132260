.title-name {
  padding: 18px;
}

.editor__container {
  display: flex;
  flex-direction: row;
}

.image-video-edit {
  /* padding: 18px; */
  /* box-shadow: 0 0 3px 2px darkgrey; */
  margin: 15px;
  background-color: white;
}

.edit-buttons {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  flex-wrap: wrap;
  margin: 20px;
}

.edit-buttons > button {
  margin-bottom: 4px;
  border: 1px solid #222224;
  color: #222224;
  border-radius: 30px;
}

.edit-form-container {
  background-color: lightgrey;
  padding: 20px;
  background-color: #fcfcfc;
  border-radius: 20px;
  box-shadow: 1px 2px 4px 0px #989898;
  margin: 20px;
  width: 100%;
  max-height: 90vh;

  overflow: scroll;
  margin-top: 50px;
}

.update__button {
  position: fixed;
  top: 70px;
  right: 90px;
  width: 200px;
  border-radius: 40px;
  height: 52px;
  z-index: 1000;
}

.update-edit-buttons {
  padding: 20px;
}

.edit-form-container > input {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  font-weight: 600;
  border: none;
}

.text-edit {
  margin: 10px;
  /* padding: 18px; */
  /* box-shadow: 0 0 3px 2px lightgrey; */
  margin: 15px;
  background-color: white;
}

.quiz-edit {
  padding: 18px;
  margin: 15px;
  background-color: white;
}

.quiz-edit-question {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.likert {
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
  /* width: 40%; */
  width: 500px;
  padding-bottom: 0;
  margin: 15px;
}

.likert > textarea {
  width: 100%;
}

.likert > p {
  font-weight: bold;
  padding: 20px;
  padding-bottom: 0px;
  font-family: Poppins;
  font-weight: 700;
}

.carousel-container {
  /* width: 500px;
  height: auto; */
}

.carousel-wrapper {
  background-color: white;
  box-shadow: 0 0 3px #888484;
}

.optioninput__text {
  font-family: Poppins;
  font-weight: 400;
  width: 610px;
  height: 52px;
  font-size: 16px;

  border: 1px solid rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
  border-radius: 16px;
  padding-left: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  /* margin-bottom: 16px; */
}

@media only screen and (max-width: 700pxpx) {
  .likert {
    width: 100%;
  }
}
