.review__container {
  width: 600px;
  display: flex;
  flex-direction: row;
}

/* I want the review_container on the odd children to have a different color so it's like a table */
.review__container:nth-child(odd) {
  background-color: #f2f2f2;
}

.review__text {
  /* flex: 1; */
  padding-right: 12px;
  width: 200px;
}

.review__rating {
  /* flex: 1; */
  text-align: center;
  width: 100px;
}

.reviews__container {
  display: flex;
  flex-direction: column;
}

.reviewstable__container {
  display: flex;
  flex-direction: column;
}

.reviewspage__container {
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.reviews__row:nth-child(odd) {
  background-color: #FFF8F8;
}
