.finishedcourse__container {
  padding: 20px;
  /* border: 1px solid lightgrey; */
  font-family: Poppins;
  box-shadow: 0px 0px 6px 3px rgb(75 75 75 / 10%);
  background-color: white;
  border-radius: 10px;
  margin-bottom: 50px;
}

.finishedcourse__container > h1 {
  font-family: Poppins;
  font-size: 28px;
}

.finishedcourse__container > h5 {
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
}
