.qrcodes__contentcontainer {
  /* background-color: rgba(217, 70, 69, 0.2); */
  padding: 20px;
  border-radius: 20px;
  margin: 20px;
  max-width: 600px;
  justify-content: space-between;
  font-family: Poppins;
}

.qrcodes__container {
  display: flex;
  justify-content: center;
  font-family: Poppins;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

.scan__title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: Poppins;
}

.device__text {
  font-size: 16px;
  font-family: Poppins;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}

/* max width 760 */
@media screen and (max-width: 760px) {
  .qrcodes__contentcontainer {
    padding: 10px;
    margin: 10px;
  }
  .qrcodes__container {
    flex-direction: column;
    gap: 10px;
  }
  .scan__title {
    font-size: 20px;
  }
  .device__text {
    font-size: 16px;
  }
}
